.login-card {
  background-image: linear-gradient(315deg, #0000008a -16%, #2d454f 56%);
  height: 280px;
  border-radius: 10px;
  //   border: 2px solid #2d454f;
}
.logo {
  object-fit: cover;
  img {
    height: 150px;
    width: 300px;
  }
}
.btn-center {
  Button {
    background: linear-gradient(to bottom right, #ffff00 0%, #ff3300 100%);
    border: 2px solid black;
    border-radius: 10px;
    color: black;
  }
}
.input-style {
  background-color: black;
  height: 50px;
}

.btn-adminstore{
    border-radius: 20px;
    background-color: #c2c6c7;
    border-color: #c2c6c7;
    color: black;
    
}
.btn-darkblue{
    height: 40px;
    width: 140px;
    background-color: #7ba0a9;
    border-color: #7ba0a9;
    color: black;
}
.btn-darkblue:hover{
    background-color: #172d38;
    border-color: #172d38;
    color: white ;
}
.btn-filter{
    width: 140px;
    background-color: #7ba0a9;
    border-color: #7ba0a9;
    color: black;
}
.btn-filter:hover{
    background-color: #172d38;
    border-color: #172d38;
    color: white ;
}
.days-btn-section{
    .btn-filter{
        font-size: 14px;
    }
}
.arrow-icon{
    img{
        opacity: 0.5;
    }
}

@media only screen and (max-width: 600px){
    h6{
        font-size: 12px !important;
    }
    
}
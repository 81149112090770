.toggle-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  /* top: 2px; */
  width: 15px;
  height: 14px;
  background-color: #aaa;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(12px);
  background-color: #f9f9f9;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #26a2ad;
}